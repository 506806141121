import * as React from "react";
import {useState, useEffect} from "react";
import {getImage} from "gatsby-plugin-image"
import {graphql, PageProps} from "gatsby";
import Cookies from "universal-cookie";

import CleanDataParser from "../helpers/editorjs-parser";
import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import Block4Image8Text from "../components/blocks/block-4image-8text";
import Section from "../components/section";
import StepSection from "../components/step-section";
import ServiceTabs from "../components/tab/services-tabs";
import BenefitsSliderServices from "../components/slider/benefits-slider-services";
import TemplateHead from "../components/template-head";

import "../styles/page-services.scss";
import SafeBlock from "../components/safe-block";
const cookies = new Cookies();

type DataProps = {
  strapiAdvisor: any,
  burialPageData: any,
}

export default function GravesideBurial({data: {strapiAdvisor, burialPageData}}: PageProps<DataProps>) {
  const [servicePrice, setServicePrice] = useState("");
  const [regionalPrice, setRegionalPrice] = useState(false);

  const cookieHub = cookies.get("fenix4hub");
  useEffect(() => {
    setServicePrice(burialPageData?.service?.regionalPrice.filter(function (el) {return el.hubSlug === cookieHub})[0]?.hubSlug && cookieHub !== undefined ?  burialPageData?.service?.regionalPrice.filter(function (el) {return el.hubSlug === cookieHub})[0]?.hubPrice : burialPageData?.service?.price);
    setRegionalPrice(burialPageData?.service?.regionalPrice && burialPageData?.service?.regionalPrice.filter(function (el) {return el.hubSlug === cookieHub})[0]?.hubSlug )
  }, []);
  const imageSimple = getImage(burialPageData?.service?.simpleImage?.localFile)
  const advisor = strapiAdvisor

  const priceText = (
    <div className="price-text mt-2 text-white">
      {burialPageData?.service?.priceText?.data?.priceText ? (
        JSON.parse(burialPageData?.service?.priceText?.data?.priceText).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textSimple = (
    <div className="">
      {burialPageData?.service?.textSimple?.data?.textSimple ? (
        JSON.parse(burialPageData?.service?.textSimple?.data?.textSimple).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textHowItWorks = (
    <div className="">
      {burialPageData?.service?.textHowItWorks?.data?.textHowItWorks ? (
        JSON.parse(burialPageData?.service?.textHowItWorks?.data?.textHowItWorks).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--funeral pl-4 pr-4"
        bgImageClassName="hero-image hero-image--funeral"
        contentClassName="hero-content hero-content--funeral"
        altImages="Graveside Burial Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {name: "Graveside Burial", slug: "/graveside-burial/"},
        ]}
      >
        <HeroContentHeading
          headingTitle={burialPageData?.service?.title}
          headingContent={burialPageData?.service?.subtitle}
          priceFrom={"from "}
          price={servicePrice}
          priceList={priceText}
          regionPrice={regionalPrice}
          advisorData={advisor}
          citeText=""
          safeBadge={true}
        />
      </Hero>
      <Section>
        <Block4Image8Text
          rowClass="row no-gutters d-flex flex-column flex-md-row "
          col1Class="col-12 col-md-4 col-top-left"
          col2Class="col-12 col-md-8 col-bottom-right bg-white pl-4 pl-md-5 pr-4 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5 col-text"
          imageOptimized={imageSimple}
          h2={burialPageData?.service?.titleSimple}
          text={textSimple}
        />
      </Section>
      <Section
        sectionClass={
          "bg-deepblue pl-0 pl-md-4 pr-0 pr-md-4 pt-4 section-container section-container--tabs"
        }
      >
        <ServiceTabs
          includedServices={burialPageData?.service?.tab}
        />
        <img
          src="/images/stripe_1.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--services d-none d-lg-block"
        />
      </Section>
      <BenefitsSliderServices
        benefitsData={burialPageData?.service?.slider}
        sliderClass="bg-deepblue pl-0 pr-0 pl-sm-4 pr-sm-4 pt-5 pt-md-0 pb-4 service-benefits"
      />
      <Section
        sectionClass={"section-container safe-section pl-4 pr-4 pb-0 pb-lg-3 pb-lg-5"}
      >
        <SafeBlock/>
      </Section>
      <StepSection
        stepData={burialPageData?.service?.guidance}
        stepDataTitle2={burialPageData?.service?.titleHowItWorks}
        stepDataSubtitle2={textHowItWorks}
        stepDataCta={"Begin planning a funeral"}
      />
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="Graveside Burial - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/graveside-burial/"
      alternate="https://fenixfuneral.co.uk/graveside-burial/"
      canonical="https://fenixfuneral.co.uk/graveside-burial/"
      og={{
        url: "https://fenixfuneral.co.uk/graveside-burial/",
        title: "Graveside Burial - Fenix Funeral Directors",
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "position": "1",
          "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"},
          {
          "@type": "ListItem",
          "position": "2",
          "name": "Graveside Burial","item": "https://fenixfuneral.co.uk/graveside-burial/"
          }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        burialPageData: strapiServiceGravesideBurial {
            service {
                title
                titleHowItWorks
                textHowItWorks {
                    data {
                        textHowItWorks
                    }
                }
                titleSimple
                textSimple {
                    data {
                        textSimple
                    }
                }
                simpleImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
                tab {
                    title
                    text {
                        data {
                            text
                        }
                    }
                }
                subtitle
                slug
                priceCurrency
                price
                regionalPrice {
                    hubSlug
                    hubPrice
                }
                priceText {
                    data {
                        priceText
                    }
                }
                slider {
                    title
                    textMain
                    icon {
                        url
                    }
                }
                guidance {
                    titleNumber
                    textGuide {
                        data {
                            textGuide
                        }
                    }
                }
            }
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400,
                            height: 400,
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
    }
`;