import * as React from "react";
import {Link} from "gatsby";
import TrustpilotMicro from "./trustpilot/trustpilot-micro";

export default function SafeBlock(props: any) {
  const safeText = "We understand that arranging a funeral is expensive and, for many, paying funeral costs upfront is challenging. Fenix Funeral is working in partnership with Funeral Safe who offer unsecured personal loans specifically to cover funeral costs."

  return (
    <div className="safe-section">
      <div className="block-wrapper">
        <div className="d-flex flex-column flex-lg-row bg-white overflow-hidden">
          <img src="/images/safe-bg.jpg" alt="safe plan" className="safe-section__bg"/>
          <div className="bg-white safe-section__text">
            <h3 className="">Could a personal loan to cover funeral expenses be right for you?</h3>
            <div className="text-wrap text-deepblue">
              <div className="text-wrap">{safeText}</div>
              <Link to="/guidance/funeral-financing/funeral-loans/"
                    className="text-lightblue bg-transparent no-border p-0 mt-3 d-block">Learn more</Link>
            </div>
            <div className="d-flex align-items-center safe-section__row">
              <Link className="" to={"/"}>
                <img src="/images/Logo-Dark.svg" alt="Fenix Funeras Ltd logo" width="105" height="30"/>
              </Link>
              <Link to="/guidance/funeral-financing/funeral-loans/" title="Funeral safe"><img
                src="/images/icon-safe.svg" alt="Safe logo" className="ml-2 ml-lg-4"/></Link>
              <div className="d-block d-lg-none">
                <TrustpilotMicro
                  theme='light'
                  height={'50px'}
                /></div>
              <div className="d-none d-lg-block">
                <TrustpilotMicro
                  theme='light'
                  height={'20px'}
                /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}