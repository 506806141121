import * as React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {useFenixContext} from "../../helpers/context";

export default function Block4Image8Text(props: any) {
  const {state, dispatch} = useFenixContext();
  return (
    <div className="block-wrapper">
      <div className={props.rowClass}>
        <div className={props.col1Class}>
          <GatsbyImage alt={"Fenix Funeral Directors"} image={props.imageOptimized} className="gatsby-bg"/>
        </div>
        <div className={props.col2Class}>
          <h3 className="mb-4">{props.h2}</h3>
          <div className="col-content">{props.text}</div>
          {props.link ?
            <a href={props.link} className="follow-link d-block mt-4 text-darkblue"><u>{props.linkText}</u></a> : ``}
          <button
            onClick={() => dispatch({type: "modalBooking"})}
            data-toggle="modal"
            data-target="#bookMeetingForm"
            className="btn bg-transparent text-darkblue p-0 mt-4 contact-link gtm-strongintent"
            title="Book consultation"
          >Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}